import { colors, opacity } from "./userColors";

const theme1 = {
	dark: {
		mode: "dark",
		primaryColor: "#ffcd38",
		fadedPrimaryColor: "#ffcd3833",
		hoverPrimaryColor: "#fab433",
		gradientStartPrimaryColor: "#ffcd38C2",
		gradientEndPrimaryColor: "#FFE592D4",
		shadowColor: "#00000029",
		selected: {
			100: "#c561de",
			300: "#b54cbb",
			500: "#8a3392",
		},
		error: {
			backgroundColor: "#E6575733",
			textColor: "#FF6060",
		},
		feedback: {
			successColor: "#78CE5E",
			errorColor: "#FF706B",
			warningColor: "#E68357",
			infoColor: "#59B6FF",
			infoBackgroundColor: "#59B6FF33",
		},
		sideMenu: {
			backgroundColor: "#2B343B",
			iconColor: "#FFFFFF",
		},
		breadcrumb: {
			textColor: "#ffcd38",
		},
		header: {
			textColor: "#a5b9c8",
			hoverTextColor: "#ffffff",
			disabledTextColor: "#38444D",
			backgroundColor: "#2B343B",
			borderColor: "#1e272e",
		},
		page: {
			backgroundColor: "#1E272E",
			secondaryBackgroundColor: "#38444D",
			tertiaryBackgroundColor: "#2b343b",
			quaternaryBackgroundColor: "#38444D",
			borderColor: "#242d34",
			secondaryBorderColor: "#2b343b",
			textColor: "#ffffff",
			fadedHoverBackgroundColor: "#ffffff10",
			secondaryTextColor: "#a5b9c8",
		},
		charts: {
			axisTextColor: "#a5b9c8",
			dataTextColor: "#ffffff",
			dataSecondaryTextColor: "#000000",
			backgroundColor: "#2B343B",
			gridLinesColor: "#51646f",
			axisLinesColor: "#ffffff",
		},
		tabs: {
			tab: {
				borderColor: "transparent",
			},
		},
		button: {
			textColor: "#495965",
			borderColor: "#C4C4C4",
		},
		banner: {
			backgroundColorError: "#F9858C",
			textColorError: "#931F26",
		},
		profitabilityItems: {
			volume: {
				color: "#ffffff",
				gradientStartColor: "#ffffff",
				gradientEndColor: "#ffffff",
			},
			pureGrossProfit: {
				color: "#5790E6",
				gradientStartColor: "#5790E6",
				gradientEndColor: "#5790E6",
			},
			grossProfit: {
				// = grossMargin
				color: "#a5b9c8",
				gradientStartColor: "#A5B9C8",
				gradientEndColor: "#71889A",
			},
			netProfit: {
				// = netMargin
				color: "#78CE5E",
				gradientStartColor: "#78CE5E",
				gradientEndColor: "#97DF81",
			},
			loss: {
				color: "#FF6060",
				gradientStartColor: "#FF5A5A",
				gradientEndColor: "#FF5A5A",
			},
			grossRevenue: {
				color: "#ffa631",
				gradientStartColor: "#ffa631",
				gradientEndColor: "#FFCE8D",
			},
			netRevenue: {
				color: "#ffcd38",
				gradientStartColor: "#FFCD38",
				gradientEndColor: "#FFEFB7",
			},
			variableCost: {
				color: "#a082d2",
				gradientStartColor: "#A082D2",
				gradientEndColor: "#C1AAE9",
			},
			fixedCost: {
				color: "#67c8b8",
				gradientStartColor: "#85E6D6",
				gradientEndColor: "#B4ECE3",
			},
			semiVariableCost: {
				color: "#00CBFF",
				gradientStartColor: "#00CBFF",
				gradientEndColor: "#7FE4FE",
			},
			totalCosts: {
				color: "#9ec5ff",
				gradientStartColor: "#85E6D6",
				gradientEndColor: "#A082D2",
			},
			discount: {
				color: "#EA80FC",
				gradientStartColor: "#E657DC",
				gradientEndColor: "#EBA0E6",
			},
			markUp: {
				color: "#7FA6DF",
				gradientStartColor: "#7FA6DF",
				gradientEndColor: "#B5C7E2",
			},
			vat: {
				color: "#D4DEE5",
				gradientStartColor: "#D4DEE5",
				gradientEndColor: "#E6E9F2",
			},
			tradeOffRevenueProfit: {
				color: "#0AF7FF",
				fadedColor: "#0AF7FF1C",
				gradientStartColor: "#0AF7FF",
				gradientEndColor: "#0AF7FF",
			},
		},
		vanWestendorp: {
			marginalCheapness: {
				color: "#23CE6B",
			},
			optimalPrice: {
				color: "#CD82D2",
			},
			indifferencePrice: {
				color: "#E8871E",
			},
			marginalExpensiveness: {
				color: "#90F3E3",
			},
		},
		valueMap: {
			low: "#595e9f",
			medium: "#6b9d65",
			high: "#925357",
		},
		thermo: {
			color0: "#66CCFF",
			color01: "#27C2E5",
			color10: "#00B050",
			color11: "#1FBF35",
			color20: "#92D050",
			color21: "#92D050",
			color30: "#FFC000",
			color31: "#CC8550",
			color40: "#FF0000",
			color41: "#B51F36",
		},
		errorBoundaryModal: {
			backgroundColor: "#495965",
			dividerColor: "#404d57",
		},
		uploadImage: {
			textColor: "#a5b9c8",
			disabledTextColor: "#a5b9c8",
			hoverTextColor: "#FFFFFF",
			hoverBorderColor: "#a5b9c8",
			crossButton: {
				textColor: "#ffffff",
				hoverTextColor: "#ffffff",
				backgroundColor: "#1e272e",
				hoverBackgroundColor: "#5c6f7e",
			},
		},
		columns: {
			favorite: {
				textColor: "#a5b9c8",
				hoverTextColor: "#FFFFFF",
			},
			date: {
				valid: "#82C760",
				expiresIn: "#FF7F11",
				expired: "#EF6F6C",
				dealSigned: "#87BFFF",
				dealDeclined: "#EF6F6C",
				dealClosed: "#71889a",
				noDate: "#71889A",
			},
		},
		skeleton: {
			backgroundColor1: "#38444d",
			backgroundColor2: "#404d57",
		},
		eyeWrapper: {
			backgroundColorShow: "#5c6f7e",
			backgroundColorHide: "#38444d",
			shadowColor: "#00000040",
			hoverBackgroundColor: "#404e57",
			primaryBorderColor: "#a5b9c8",
			secondaryBorderColor: "#5c6f7e",
			hoverBorderColor: "#a5b9c8cc",
		},
		iconBox: {
			backgroundColor: "#485865",
			hoverBackgroundColor: "#71889a",
			shadowColor1: "#0000001f",
			shadowColor2: "#0000003d",
			icon: {
				disableTextColor: "#8E8E8E",
				textColor: "#a5b9c8",
				hoverTextColor: "#a5b9c8",
			},
		},
		lastUpdateSection: {
			backgroundColor: "#38444d",
			hoverBackgroundColor: "#495965",
			disabledBackgroundColor: "#404d57",
			shadowColor: "#00000029",
			primaryTextColor: "#ffffff",
			secondaryTextColor: "#a5b9c8",
			disabledPrimaryTextColor: "#71889a",
		},
		tableGroupByWidget: {
			textColor: "#ffffff",
		},
		tableFilterWidget: {
			selectBackgroundColor: "#485964",
			backgroundColor: "#495965",
			textColor: "#ffffff",
			secondaryTextColor: "#a5b9c8",
			hoverTextColor: "#71889A30",
			primaryBorderColor: "#1E272E",
			secondaryBorderColor: "#5C6F7E",
			tertiaryBorderColor: "#ffffff",
			shadowColor: "#00000029",
			checkboxesWrapperBackgroundColor: "#475662",
		},
		drawer: {
			disabledTextColor: "#8E8E8E66",
			primaryTextColor: "#a5b9c8",
			secondaryTextColor: "#FFFFFF",
			scrollbarColor: "#71889a",
			scrollBarTrackBackgroundColor: "#38444d",
			scrollBarThumbBackgroundColor: "#71889a",
			scrollBarThumbBorderColor: "#71889a",
			borderColor: "#1e272e",
			header: {
				borderColor: "#1e272e",
			},
			section: {
				borderColor: "#38444d",
			},
			otherActions: {
				arrow: {
					backgroundColor: "#ffcd38",
					hoverBackgroundColor: "#fff",
				},
				tippy: {
					backgroundColor: "#38444d",
					secondaryBackgroundColor: "#404d57",
					shadowColor: "#00000040",
					textColor: "#a5b9c8",
					hoverTextColor: "#ffffff",
					disabledTextColor: "#8E8E8E",
				},
			},
			offers: {
				primaryTextColor: "#ffcd38",
				secondaryTextColor: "#ffffff",
				tertiaryTextColor: "#a5b9c8",
				tertiaryHoverTextColor: "#a5b9c8cc",
				versionBorderColor: "#38444d",
				versionHeaderBackgroundColor: "#404d57",
				versionOlderHeaderBackgroundColor: "#495965",
				versionSectionBackgroundColor: "#5b6974",
				versionSectionDisabledTextColor: "#a5b9c8",
				versionSectionContentBackgroundColor: "#52616d",
				versionBoxHoverBackgroundColor: "#66717b",
				versionBoxShadowColor: "#00000029",
			},
		},
		offers: {
			creationModal: {
				section: {
					borderColor: "#38444d",
				},
				toggleSwitchButton: {
					backgroundColor: "#404d57",
					focusTextColor: "#404d57",
					hoverTextColor: "#3c4953",
				},
				previewButton: {
					backgroundColor: "#404d57",
					hoverBackgroundColor: "#5c6f7e",
				},
				category: {
					backgroundColor: "#38444D",
				},
				template: {
					backgroundColor: "#495965",
				},
				header: {
					title: "#ffffff",
					subTitle: "#FFCD38",
				},
			},
			pack: {
				backgroundColor: {
					disable: "#495965",
				},
			},
		},
		sliders: {
			primarySlider: { color: "#6EA8FF", focusColor: "#6EA8FF", hoverColor: "#9EC5FF" }, //TODO: à ajouter partout
			secondarySlider: { color: "#FF92F7", focusColor: "#FF92F7", hoverColor: "#EBA0E6" }, //TODO: à ajouter partout
		},
		tippy: {
			backgroundColor: "#495965",
			textColor: "#fff",
			borderColor: "#5C6F7E",
			hoverBackgroundColor: "#FFCD381a",
		},
		dragAndDrop: {
			backgroundColor: "#495965",
			hoverBackgroundColor: "#71889A",
		},
		//! TODO: all the colors below are deprecated
		backgroundColor: {
			primary: colors.black9,
			secondary: colors.black14,
			tertiary: colors.black7,
			quaternary: colors.black16,
			fifth: colors.black9,
			sixth: colors.white9,
			seventh: colors.grey9,
			focus: {
				primary: colors.black10,
				secondary: colors.black14,
				tertiary: colors.black14,
			},
			hover: {
				primary: `${colors.white1}${opacity.OPACITY10}`,
				secondary: colors.black11,
				tertiary: `${colors.black8}${opacity.OPACITY19}`,
			},
			icon: {
				primary: colors.black10,
				secondary: colors.black14,
				tertiary: colors.black14,
				hover: colors.black14,
			},
			box: {
				primary: colors.black13,
				secondary: colors.black13,
				tertiary: colors.black20,
			},
			highlight: {
				primary: colors.black10,
			},
			disabled: {
				primary: `${colors.black9}${opacity.OPACITY50}`,
			},
			tabs: {
				primary: colors.black9,
				secondary: colors.black16,
			},
			tooltip: {
				primary: colors.grey10,
				secondary: colors.grey6,
			},
			unFocus: {
				primary: colors.grey5,
			},
		},
		borderColor: {
			primary: colors.black9,
			secondary: colors.black11,
			tertiary: colors.black13,
			quaternary: colors.black9,
			fifth: colors.black8,
			sixth: colors.black9,
			focus: {
				primary: colors.black10,
			},
			box: {
				primary: colors.black10,
			},
		},
		textColor: {
			primary: colors.white1,
			secondary: colors.black11,
			tertiary: colors.black11,
			quarternary: colors.black13,
			fifth: colors.white1,
			sixth: colors.white1,
			seventh: colors.white1,
			highlight: {
				primary: colors.white1,
				secondary: colors.white1,
			},
			highlightHovered: `${colors.white1}${opacity.OPACITY80}`,
			title: {
				primary: colors.white1,
			},
			subtitle: {
				primary: colors.black11,
				secondary: colors.white4,
				tertiary: colors.white4,
				quaternary: colors.black8,
				quinary: colors.grey16,
				senary: colors.grey16,
			},
			icon: {
				primary: colors.black13,
				secondary: colors.white1,
				hover: colors.white1,
			},
			hover: {
				primary: colors.black7,
			},
			tooltip: {
				primary: colors.white10,
			},
		},
		crossButton: {
			backgroundColor: {
				primary: colors.black14,
				hover: colors.black10,
			},
			crossColor: {
				primary: colors.white1,
				hover: colors.white1,
			},
		},

		tooltipPPD: {
			dark: {
				backgroundColor: "#FAFAFA",
				colorText: "#1e272e",
				border: "1px solid #71889A",
			},

			warning: {
				colorText: colors.white1,
				backgroundColor: colors.orange1,
				border: `1px solid ${colors.orange1}`,
				shadow: "0px 1px 1px rgba(0, 0, 0, 0.06)",
			},
		},

		inputPPD: {
			backgroundColor: "#FAFAFA",
			borderColor: "#D4DEE5",
		},

		rangeSelectorPPD: {
			backgroundColor: "#FAFAFA",
			borderColor: "#D4DEE5",
		},

		singleSelectPPD: {
			backgroundColor: "#FAFAFA",
			borderColor: "#D4DEE5",
		},

		checkboxPPD: {
			borderColor: "#D4DEE5",
			backgroundColor: "#A5B9C8",
		},

		modalAdditionalQuoteFields: {
			labelColor: "#A5B9C8",
		},
	},
	light: {
		mode: "light",
		primaryColor: "#F2b500",
		fadedPrimaryColor: "#F2b50036",
		hoverPrimaryColor: "#fab433",
		gradientStartPrimaryColor: "#F2b500C2",
		gradientEndPrimaryColor: "#FFE592D4",
		shadowColor: "#0000001C",
		error: {
			backgroundColor: "#E6575733",
			textColor: "#FF6060",
		},
		feedback: {
			successColor: "#78CE5E",
			errorColor: "#ef233c",
			warningColor: "#E68357",
			infoColor: "#59B6FF",
			infoBackgroundColor: "#59B6FF33",
		},
		sideMenu: {
			backgroundColor: "#FFFFFF",
			iconColor: "#848484",
		},
		header: {
			textColor: "#71889a",
			hoverTextColor: "#434D56",
			disabledTextColor: "#8E8E8E",
			backgroundColor: "#FFFFFF",
			borderColor: "#FAFAFA",
		},
		breadcrumb: {
			textColor: "#000000",
		},
		page: {
			backgroundColor: "#FAFAFA",
			secondaryBackgroundColor: "#FAFAFA",
			tertiaryBackgroundColor: "#ffffff",
			quaternaryBackgroundColor: "#ffffff",
			borderColor: "#E7E7E7",
			secondaryBorderColor: "#fff",
			fadedHoverBackgroundColor: "#00000010",
			textColor: "#000000",
			secondaryTextColor: "#71889a",
		},
		charts: {
			axisTextColor: "#000000",
			dataTextColor: "#000000",
			dataSecondaryTextColor: "#000000",
			backgroundColor: "#ffffff",
			gridLinesColor: "#E5E9EC",
			axisLinesColor: "#000000",
		},
		tabs: {
			tab: {
				borderColor: "#F1F3F4",
			},
		},
		button: {
			textColor: "#495965",
			borderColor: "#C4C4C4",
		},
		banner: {
			backgroundColorError: "#F9858C",
			textColorError: "#931F26",
		},
		profitabilityItems: {
			volume: {
				color: "#FF7F11",
				gradientStartColor: "#FF7F11",
				gradientEndColor: "#FC9F6CB8",
			},
			pureGrossProfit: {
				color: "#5790E6",
				gradientStartColor: "#5790E6",
				gradientEndColor: "#5790E6",
			},
			grossProfit: {
				// = grossMargin
				color: "#a5b9c8",
				gradientStartColor: "#A5B9C8",
				gradientEndColor: "#71889A",
			},
			netProfit: {
				// = netMargin
				color: "#78CE5E",
				gradientStartColor: "#78CE5E",
				gradientEndColor: "#97DF81",
			},
			loss: {
				color: "#FF6060",
				gradientStartColor: "#FF5A5A",
				gradientEndColor: "#FF7276",
			},
			grossRevenue: {
				color: "#ffa631",
				gradientStartColor: "#ffa631",
				gradientEndColor: "#FFCE8D",
			},
			netRevenue: {
				color: "#4FA1FF",
				gradientStartColor: "#4FA1FF",
				gradientEndColor: "#87BFFFCF",
			},
			variableCost: {
				color: "#a082d2",
				gradientStartColor: "#A082D2",
				gradientEndColor: "#C1AAE9",
			},
			fixedCost: {
				color: "#67c8b8",
				gradientStartColor: "#85E6D6",
				gradientEndColor: "#B4ECE3",
			},
			semiVariableCost: {
				color: "#00CBFF",
				gradientStartColor: "#00CBFF",
				gradientEndColor: "#7FE4FE",
			},
			totalCosts: {
				color: "#9ec5ff",
				gradientStartColor: "#85E6D6",
				gradientEndColor: "#A082D2",
			},
			discount: {
				color: "#EA80FC",
				gradientStartColor: "#E657DC",
				gradientEndColor: "#EBA0E6",
			},
			markUp: {
				color: "#7FA6DF",
				gradientStartColor: "#7FA6DF",
				gradientEndColor: "#B5C7E2",
			},
			vat: {
				color: "#D4DEE5",
				gradientStartColor: "#D4DEE5",
				gradientEndColor: "#E6E9F2",
			},
			tradeOffRevenueProfit: {
				color: "#0AF7FF",
				fadedColor: "#0AF7FF1C",
				gradientStartColor: "#0AF7FF",
				gradientEndColor: "#0AF7FF",
			},
		},
		vanWestendorp: {
			marginalCheapness: {
				color: "#23CE6B",
			},
			optimalPrice: {
				color: "#CD82D2",
			},
			indifferencePrice: {
				color: "#E8871E",
			},
			marginalExpensiveness: {
				color: "#90F3E3",
			},
		},
		valueMap: {
			low: "#595e9f",
			medium: "#6b9d65",
			high: "#925357",
		},
		thermo: {
			color0: "#7FC8F8",
			color01: "#6ABDE7",
			color10: "#5DD39E",
			color11: "#4BBC89",
			color20: "#DAF7A6",
			color21: "#C9E498",
			color30: "#F4A25E",
			color31: "#CC8550",
			color40: "#EF6F6C",
			color41: "#D05C59",
		},
		errorBoundaryModal: {
			backgroundColor: "#495965",
			dividerColor: "#404d57",
		},
		uploadImage: {
			textColor: "#a5b9c8",
			disabledTextColor: "#a5b9c8",
			hoverTextColor: "#71889a",
			hoverBorderColor: "#a5b9c8",
			crossButton: {
				textColor: "#ffffff",
				hoverTextColor: "#ffffff",
				backgroundColor: "#5c6f7e",
				hoverBackgroundColor: "#1e272e",
			},
		},
		columns: {
			favorite: {
				textColor: "#a5b9c8",
				hoverTextColor: "#71889a",
			},
			date: {
				valid: "#82C760",
				expiresIn: "#FF7F11",
				expired: "#EF6F6C",
				dealSigned: "#87BFFF",
				dealDeclined: "#EF6F6C",
				dealClosed: "#71889a",
				noDate: "#71889A",
			},
		},
		skeleton: {
			backgroundColor1: "#eceff1",
			backgroundColor2: "#D1D1D1",
		},
		eyeWrapper: {
			backgroundColorShow: "#fafafa",
			backgroundColorHide: "#ffffff",
			shadowColor: "#0000001C",
			hoverBackgroundColor: "#ffffff",
			primaryBorderColor: "#a5b9c8",
			secondaryBorderColor: "#71889a",
			hoverBorderColor: "#a5b9c8cc",
		},
		iconBox: {
			backgroundColor: "#ffffff",
			hoverBackgroundColor: "#ffffff",
			shadowColor1: "#0000001f",
			shadowColor2: "#0000003d",
			icon: {
				disableTextColor: "#8E8E8E",
				textColor: "#71889a",
				hoverTextColor: "#434D56",
			},
		},
		lastUpdateSection: {
			backgroundColor: "#f1f1f1",
			hoverBackgroundColor: "#eceff1",
			disabledBackgroundColor: "#404d57",
			shadowColor: "#0000001C",
			primaryTextColor: "#434D56",
			secondaryTextColor: "#71889a",
			disabledPrimaryTextColor: "#a5b9c8",
		},
		tableGroupByWidget: {
			textColor: "#434D56",
		},
		tableFilterWidget: {
			selectBackgroundColor: "#ffffff",
			backgroundColor: "#fafafa",
			textColor: "#434D56",
			secondaryTextColor: "#71889a",
			hoverTextColor: "#434D5630",
			primaryBorderColor: "#E7E7E7",
			secondaryBorderColor: "#D4DEE5",
			tertiaryBorderColor: "#1e272e",
			shadowColor: "#0000001C",
			checkboxesWrapperBackgroundColor: "#ffffff",
		},
		drawer: {
			disabledTextColor: "#8E8E8E66",
			primaryTextColor: "#71889a",
			secondaryTextColor: "#434D56",
			scrollbarColor: "#71889a",
			scrollBarTrackBackgroundColor: "#38444d",
			scrollBarThumbBackgroundColor: "#71889a",
			scrollBarThumbBorderColor: "#71889a",
			borderColor: "#E7E7E7",
			header: {
				borderColor: "#E7E7E7",
			},
			section: {
				borderColor: "#f1f1f1",
			},
			otherActions: {
				arrow: {
					backgroundColor: "#71889a",
					hoverBackgroundColor: "#71889acc",
					focusBackgroundColor: "#fafafa",
				},
				tippy: {
					backgroundColor: "#fafafa",
					secondaryBackgroundColor: "#ffffff",
					shadowColor: "#0000001C",
					textColor: "#434D56",
					hoverTextColor: "#434D56CC",
					disabledTextColor: "#8E8E8E",
				},
			},
			offers: {
				primaryTextColor: "#F2b500",
				secondaryTextColor: "#434D56",
				tertiaryTextColor: "#71889a",
				tertiaryHoverTextColor: "#71889acc",
				versionBorderColor: "#E7E7E7",
				versionHeaderBackgroundColor: "#fafafa",
				versionOlderHeaderBackgroundColor: "#fafafa",
				versionSectionBackgroundColor: "#f1f1f1",
				versionSectionDisabledTextColor: "#a5b9c8",
				versionSectionContentBackgroundColor: "#ffffff",
				versionBoxHoverBackgroundColor: "#fafafa",
				versionBoxShadowColor: "#0000001C",
			},
		},
		offers: {
			creationModal: {
				section: {
					borderColor: "#E7E7E7",
				},
				toggleSwitchButton: {
					backgroundColor: "#ffffff",
					focusTextColor: "#fafafa",
					hoverTextColor: "#434D56",
				},
				previewButton: {
					backgroundColor: "#ffffff",
					hoverBackgroundColor: "#fafafa",
				},
				category: {
					backgroundColor: "#e1e1e1",
				},
				template: {
					backgroundColor: "#ffffff",
				},
				header: {
					title: "#000000",
					subTitle: "#F2b500",
				},
			},
			pack: {
				backgroundColor: {
					disable: "#eeeeee",
				},
			},
		},
		sliders: {
			primarySlider: { color: "#8048A2", focusColor: "#8048A2", hoverColor: "#9D72B8" }, //TODO: à ajouter partout
			secondarySlider: { color: "#FF92F7", focusColor: "#FF92F7", hoverColor: "#EBA0E6" }, //TODO: à ajouter partout
		},
		tippy: {
			backgroundColor: "#fff",
			textColor: "#404D57",
			borderColor: "#D4DEE5",
			hoverBackgroundColor: "#FFCD381a",
		},
		//! TODO: all the colors below are deprecated
		backgroundColor: {
			primary: colors.white15,
			secondary: colors.white1,
			tertiary: colors.white1,
			quaternary: colors.white5,
			fifth: colors.white6,
			sixth: colors.black23,
			seventh: colors.grey9,
			eighth: colors.white11,
			ninth: colors.white12,
			tenth: colors.white13,
			focus: {
				primary: colors.white5,
				secondary: colors.white5,
				tertiary: colors.white1,
			},
			hover: {
				primary: colors.white5,
				secondary: colors.white4,
				tertiary: `${colors.black11}${opacity.OPACITY19}`,
			},
			icon: {
				primary: colors.transparent,
				secondary: colors.transparent,
				tertiary: colors.black14,
				hover: colors.grey8,
			},
			box: {
				primary: colors.white1,
				secondary: colors.white5,
				tertiary: colors.white1,
			},
			highlight: {
				primary: colors.white6,
				secondary: colors.grey12,
			},
			disabled: {
				primary: `${colors.black8}${opacity.OPACITY20}`,
			},
			tabs: {
				primary: colors.white15,
				secondary: colors.transparent,
			},
			tooltip: {
				primary: colors.grey10,
				secondary: colors.grey6,
			},
			unFocus: {
				primary: colors.grey5,
			},
		},
		borderColor: {
			primary: colors.black11,
			secondary: colors.black11,
			tertiary: colors.black11,
			quaternary: colors.black8,
			fifth: colors.black8,
			sixth: colors.grey13,
			focus: {
				primary: colors.black11,
			},
			box: {
				primary: colors.white4,
			},
		},
		textColor: {
			primary: colors.black14,
			secondary: colors.black11,
			tertiary: colors.black8,
			quarternary: colors.black13,
			fifth: colors.black13,
			sixth: colors.grey11,
			seventh: colors.black16,
			highlight: {
				primary: colors.black9,
				secondary: colors.white1,
			},
			highlightHovered: `${colors.black9}${opacity.OPACITY80}`,
			title: {
				primary: colors.black8,
				secondary: colors.grey14,
			},
			subtitle: {
				primary: colors.black14,
				secondary: colors.black14,
				tertiary: colors.black8,
				quaternary: colors.black8,
				quinary: colors.grey13,
				senary: colors.grey16,
			},
			icon: {
				primary: colors.white4,
				secondary: colors.black11,
				hover: colors.black7,
			},
			hover: {
				primary: colors.black7,
			},
			tooltip: {
				primary: colors.white10,
			},
		},
		crossButton: {
			backgroundColor: {
				primary: colors.white10,
				hover: colors.grey9,
			},
			crossColor: {
				primary: colors.white13,
				hover: colors.white13,
			},
		},

		tooltipPPD: {
			light: {
				backgroundColor: "#FAFAFA",
				colorText: "#1e272e",
				border: "1px solid #71889A",
			},

			warning: {
				colorText: colors.white1,
				backgroundColor: colors.orange1,
				border: `1px solid ${colors.orange1}`,
				shadow: "0px 1px 1px rgba(0, 0, 0, 0.06)",
			},
		},

		inputPPD: {
			backgroundColor: "#FAFAFA",
			borderColor: "#D4DEE5",
		},

		rangeSelectorPPD: {
			backgroundColor: "#FAFAFA",
			borderColor: "#D4DEE5",
		},

		singleSelectPPD: {
			backgroundColor: "#FAFAFA",
			borderColor: "#D4DEE5",
		},

		checkboxPPD: {
			borderColor: "#D4DEE5",
			backgroundColor: "#A5B9C8",
		},
		modalAdditionalQuoteFields: {
			labelColor: "#A5B9C8",
		},
	},
};

export default theme1;
