import { gql } from "@apollo/client";
import {
	CRM_CONNECTION_FRAGMENT,
	CRM_CONNECTION_SERVICE_FRAGMENT,
	CRM_CONNECTION_SERVICE_DETAILS_FRAGMENT,
	CRM_CONNECTION_SERVICE_INFOS_FRAGMENT,
	DEAL_SEARCH_FRAGMENT,
} from "@src/graphQl/fragments/crmConnection";

export const GET_AVAILABLE_CRM_SERVICES = gql`
	${CRM_CONNECTION_SERVICE_DETAILS_FRAGMENT}
	query GetAvailableCrmServices {
		getAvailableCrmServices {
			...CrmConnectionServiceDetailsFragment
		}
	}
`;

export const GET_SERVICE_BY_ID = gql`
	${CRM_CONNECTION_SERVICE_FRAGMENT}
	query GetAvailableServices($id: ID!) {
		getCrmServiceById(id: $id) {
			...CrmConnectionServiceFragment
		}
	}
`;

export const GET_WORKSPACE_CRM_CONNECTIONS = gql`
	${CRM_CONNECTION_FRAGMENT}
	query GetWorkspaceCrmConnections {
		getWorkspaceCrmConnections {
			...CrmConnectionFragment
		}
	}
`;

export const SEARCH_DEALS = gql`
	${DEAL_SEARCH_FRAGMENT}
	query SearchDeals(
		$connectionId: ID!
		$pipelineId: String
		$contactIds: [String]
		$ownerId: String
		$query: String
		$companyId: String
	) {
		getCrmConnectionById(id: $connectionId) {
			id
			searchDeals(
				pipelineId: $pipelineId
				contactIds: $contactIds
				ownerId: $ownerId
				companyId: $companyId
				query: $query
			) {
				deals {
					...DealSearchFragment
				}
				total
			}
		}
	}
`;

export const GET_SEARCH_DEAL_BY_ID = gql`
	${DEAL_SEARCH_FRAGMENT}
	query GetSearchDeal($connectionId: ID!, $dealId: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			deal(id: $dealId) {
				...DealSearchFragment
			}
		}
	}
`;

export const GET_DEAL_BY_ID = gql`
	query GetDeal($connectionId: ID!, $dealId: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			deal(id: $dealId) {
				id
				name
				amount
				currency
				createdAt
				pipeline {
					id
					name
				}
				stage {
					id
					name
				}
				contacts {
					id
					firstName
					lastName
					email
					address
					city
					zip
					country
				}
				companies {
					id
					name
					address
					city
					zip
					country
				}
				owner {
					id
					firstName
					lastName
					email
				}
			}
		}
	}
`;

export const GET_WORKSPACE_CRM_CONNECTIONS_SERVICE_INFOS = gql`
	${CRM_CONNECTION_SERVICE_INFOS_FRAGMENT}
	query GetWorkspaceCrmConnections {
		getWorkspaceCrmConnections {
			...CrmConnectionServiceInfosFragment
		}
	}
`;

export const GET_ALL_PIPELINES = gql`
	query GetAllPipelines($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			allPipelines {
				id
				name
			}
		}
	}
`;

export const GET_ALL_USERS = gql`
	query GetAllUsers($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			allUsers {
				id
				email
				firstName
				lastName
			}
		}
	}
`;

export const SEARCH_CONTACTS = gql`
	query SearchContacts($connectionId: ID!, $query: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			searchContacts(query: $query) {
				id
				lastName
				firstName
				email
				address
				city
				zip
				country
				companyId
			}
		}
	}
`;

export const GET_COMPANY = gql`
	query GetCompany($connectionId: ID!, $id: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			company(id: $id) {
				id
				name
				domain
				address
				city
				zip
				country
			}
		}
	}
`;

export const SEARCH_COMPANIES = gql`
	query SearchCompanies($connectionId: ID!, $query: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			searchCompanies(query: $query) {
				id
				name
				domain
				address
				city
				zip
				country
			}
		}
	}
`;

export const GET_CRM_CONNECTION_SETTINGS = gql`
	query GetCrmConnectionSettings($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			syncDeleteDeals
			syncLineItems
			syncExpiryDate
			syncStatus
			syncBidAmount
			allPipelines {
				id
				name
				stages {
					id
					name
				}
			}
			offerStatusMappings {
				pipeline
				statusMapping {
					status
					pipelineStages
				}
			}
			dealCustomProperties {
				id
				data
				key
				dealKey
				name
				type
				isActive
			}
			service {
				canSyncLineItems
				canSetDealCustomPropertyKey
				canSyncDeleteDeals
				customCardUrl
			}
		}
	}
`;

export const GET_CRM_CONNECTION_CURRENCIES = gql`
	query SearchCompanies($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			supportedCurrencies
		}
	}
`;

export const GET_CRM_CONNECTION_PIPELINE_STAGES = gql`
	query GetPipelineStages($connectionId: ID!, $pipelineId: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			pipeline(id: $pipelineId) {
				id
				stages {
					id
					name
				}
			}
		}
	}
`;

export const GET_DEAL_COMPANIES_NAMES = gql`
	query GetDealCompaniesNames($connectionId: ID!, $dealId: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			deal(id: $dealId) {
				id
				companies {
					id
					name
				}
			}
		}
	}
`;

export const GET_DEAL_CONTACTS_EMAIL = gql`
	query GetDealContactsEmail($connectionId: ID!, $dealId: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			deal(id: $dealId) {
				id
				contacts {
					id
					email
				}
			}
		}
	}
`;

export const GET_DEAL_ID = gql`
	query GetDealId($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
		}
	}
`;

export const GET_ALREADY_ASSOCIATED_DEAL_IDS = gql`
	query GetAlreadyAssociatedDealIds($connectionId: ID!) {
		getAlreadyAssociatedDealIds(connectionId: $connectionId)
	}
`;

export const GET_SALESFORCE_UPLOAD_STATUS = gql`
	query GetSalesforceUploadStatus($connectionId: ID!, $pid: String!) {
		getSalesforceUploadStatus(connectionId: $connectionId, pid: $pid) {
			id
			status
		}
	}
`;
