import { gql } from "@apollo/client";
import {
	PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_AND_PAYMENT_TERM_RANGE_ID_FRAGMENT,
	PAYMENT_TERM_RANGE_DISCOUNT_FRAGMENT,
	PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_DISCOUNT_FRAGMENT,
	PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_ENABLE_DISCOUNT_FRAGMENT,
} from "@graphQl/fragments/paymentTermRangeDiscount";

export const IS_DEFAULT_SELECTED_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedPaymentTermRangeFragment on PaymentTermRange {
		id
		isDefaultSelected
	}
`;

export const TERM_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment TermPaymentTermRangeFragment on PaymentTermRange {
		id
		term
	}
`;

export const PAYMENT_TERM_DISCOUNT_ID_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment PaymentTermDiscountIdPaymentTermRangeFragment on PaymentTermRange {
		id
		paymentTermDiscountId
	}
`;

export const PAYMENT_TERM_RANGE_TERM_AND_ERROR_FRAGMENT = gql`
	fragment PaymentTermRangeTermAndErrorFragment on PaymentTermRange {
		id
		term
		error
	}
`;

export const PAYMENT_TERM_RANGE_DEPENDENCIES_FRAGMENT = gql`
	fragment PaymentTermRangeDependenciesFramgent on PaymentTermRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const PAYMENT_TERM_RANGE_FRAGMENT = gql`
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	${PAYMENT_TERM_DISCOUNT_ID_PAYMENT_TERM_RANGE_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DEPENDENCIES_FRAGMENT}
	${IS_DEFAULT_SELECTED_PAYMENT_TERM_RANGE_FRAGMENT}
	fragment PaymentTermRangeFragment on PaymentTermRange {
		id
		uuid
		...TermPaymentTermRangeFragment
		...PaymentTermDiscountIdPaymentTermRangeFragment
		...PaymentTermRangeDependenciesFramgent
		...IsDefaultSelectedPaymentTermRangeFragment
		paymentTermDiscountRange {
			...PaymentTermRangeDiscountFragment
		}
	}
`;
export const PAYMENT_TERM_DISCOUNT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_ENABLE_DISCOUNT_FRAGMENT}
	fragment PaymentTermDiscountRangeDataToComputeIfDisabledFragment on PaymentTermRange {
		id
		paymentTermDiscountRange {
			...PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndEnableDiscountFragment
		}
	}
`;

export const PAYMENT_TERM_RANGE_PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_AND_PAYMENT_TERM_RANGE_ID_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_AND_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	fragment PaymentTermRangePaymentTermRangeDiscountPackIdAndPaymentTermRangeIdFragment on PaymentTermRange {
		id
		paymentTermDiscountRange {
			...PaymentTermRangeDiscountPackIdAndPaymentTermRangeIdFragment
		}
	}
`;

export const PAYMENT_TERM_DISCOUNT_RANGE_DATA_TO_COMPUTE_SAVINGS_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_DISCOUNT_FRAGMENT}
	fragment PaymentTermDiscountRangeDataToComputeSavingsFragment on PaymentTermRange {
		id
		paymentTermDiscountRange {
			...PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndDiscountFragment
		}
	}
`;
