import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { client } from "../../../../config/apollo";
import { ApolloProvider, useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { Navigate } from "react-router";
import { VERIFY_AUTH } from "@graphQl/queries/verifyAuth";
import WrongWorkspace from "@pages/workspaces/wrongWorkspace/WrongWorkspace";
import { useGlobalContext } from "@globalContext";
import { GET_AUTH_FOR_LOGIN } from "@graphQl/queries/user";

import { Loader } from "priceit-ui";
import analytics from "../../../../config/analytics/analytics";
import openReplayTracker from "../../../../config/openReplay";
import { updateUserDefaultWorkspaceMutation } from "@graphQl/mutations/user";
import { currentWorkspaceIdVar } from "@src/cache/cache";
import { isEmailValid } from "@pages/public/templateLogin/services/services";

export const AuthenticatedRoute = ({ children }) => {
	const { i18n } = useTranslation();
	const { workspaceId } = useParams();
	const location = useLocation();

	const {
		data: dataAuth,
		loading: loadingAuth,
		refetch,
	} = useQuery(GET_AUTH_FOR_LOGIN, {
		variables: {
			workspaceId,
		},
	});
	const auth = dataAuth?.getAuth;
	const [updateUserDefaultWorkspace] = updateUserDefaultWorkspaceMutation();

	const { light } = useGlobalContext();
	const { loading: loadingVerifyAuth, data: dataVerifyAuth } = useQuery(VERIFY_AUTH);

	useEffect(() => {
		if (auth?.language) {
			i18n.changeLanguage(auth.language);
		}
		if (auth && window.$crisp) {
			if (auth?.email && isEmailValid(auth?.email)) {
				window.$crisp.push(["set", "user:email", [auth?.email]]);
			}
			if (auth?.username) {
				window.$crisp.push(["set", "user:nickname", [auth?.username]]);
			}
		}
	}, [auth]);

	useEffect(() => {
		if (location.state?.refreshRoles) {
			refetch();
		}
	}, [location.state?.refreshRoles]);

	if (!loadingVerifyAuth && !loadingAuth) {
		if (!dataVerifyAuth?.verifyAuth) {
			return (
				<Navigate
					to="/login"
					replace
					state={{ redirectionPath: location.pathname, search: location.search }}
				/>
			);
		}
		if (analytics.user("userId") !== auth?.uuid) {
			analytics.identify(auth?.uuid, auth);
		}
		openReplayTracker.setUserID(auth?.email);

		if (
			auth.roles?.some(
				role =>
					role?.workspace.id &&
					parseInt(role.workspace.id) === parseInt(workspaceId) &&
					(role.workspace.active || auth.isRoot)
			) ||
			location.state?.refreshRoles
		) {
			currentWorkspaceIdVar(workspaceId);
			const newClient = client(null, workspaceId);
			if (auth.defaultWorkspace !== parseInt(workspaceId)) {
				updateUserDefaultWorkspace({
					variables: {
						updateUserInput: {
							defaultWorkspace: parseInt(workspaceId),
						},
					},
				});
			}

			return <ApolloProvider client={newClient}>{children}</ApolloProvider>;
		}
		if (light) {
			return <WrongWorkspace />;
		}
		return <Navigate to={`/workspaces/${auth.defaultWorkspace}`} />;
	}

	return <Loader />;
};
